const encrypt = function (str: string, key: string): string {
    return codesToString(
        composition(stringToCodes(str), stringToCodes(key), Action.Plus)
    )
}

const decrypt = function (str: string, key: string): string {
    return codesToString(
        composition(stringToCodes(str), stringToCodes(key), Action.Minus)
    )
}

const stringToCodes = function (str: string): Array<number> {
    const result: Array<number> = []
    const l: number = str.length
    let c = 0
    for (let i = 0; i < l; i++) {
        c = str.charCodeAt(i)
        if (32 <= c && c <= 126) {
            c = c - 32
        } else if (1040 <= c && c <= 1103) {
            c = c - 1040 + 95
        } else if (c == 1105) {
            c = 159
        } else if (c == 1025) {
            c = 160
        } else {
            c = Math.floor(Math.random() * 160)
        }
        result.push(c)
    }
    return result
}

const codesToString = function (list: Array<number>): string {
    let result = ''
    const l: number = list.length
    let c = 0
    for (let i = 0; i < l; i++) {
        c = list[i]
        if (c <= 94) {
            c = c + 32
        } else if (95 <= c && c <= 158) {
            c = c + 1040 - 95
        } else if (c == 159) {
            c = 1105
        } else if (c == 160) {
            c = 1025
        } else {
            c = 33
        }
        result = result + String.fromCharCode(c)
    }
    return result
}

enum Action {
    Plus = 1,
    Minus
}

const composition = function (listStr: Array<number>, listKey: Array<number>, action: Action): Array<number> {
    if (!listKey.length) {
        return listStr
    }
    const n = listStr.length + listKey.length
    let is = 0
    let ik = 0
    let sum = 0
    for (let i = 0; i < n; i++) {
        if (is == listStr.length) {
            is = 0
        }
        if (ik == listKey.length) {
            ik = 0
        }
        sum = (action == Action.Plus) ?
            listStr[is] + listKey[ik] :
            listStr[is] - listKey[ik]
        if (sum < 0) {
            sum = 160 + sum + 1
        } else if (sum > 160) {
            sum = sum - 160 - 1
        }
        listStr[is] = sum
        is++
        ik++
    }
    return listStr
}

export {encrypt, decrypt}