
import { Component, Vue } from 'vue-property-decorator'
import { encrypt, decrypt } from './Encrypt'

@Component({
  data() {
    return {
      message: "я@;сд[_P&SЁS7",
      key: ""
    }
  },
  methods: {
    encryptMsg: function (): string {
      return encrypt(this.$data.message, '')
    },
    decryptMsg: function (): string {
      return decrypt(this.$data.message, this.$data.key)
    }
  }
})
export default class App extends Vue {}
